export const API_TOKEN = 'token';
export const AUTH_HEADER = 'authorization';

export const REG_EXP = {
  string: /^[a-zA-ZА-ЩЬЮЯҐЄІЇа-щьюяґєії'ʼ-]+$/,
  phone: /((\+38)?\(?\d{3}\)?[\s/.]?(\d{7}|\d{3}[\s/.]\d{2}[\s/.]\d{2}|\d{3}\d{4}))/,
  lower: /^(?=.*[a-z])/,
  upper: /^(?=.*[A-Z])/,
  digit: /^(?=.*[0-9])/,
  specChar: /^(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?])/,
  url: /((https?:\/\/)?(www\.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?)|((https?:\/\/)?((www\.)?youtube\.com|youtu\.be)\/.+)/,
};

export const PROGRESS_BAR_COLOR = (theme) => ({
  100: theme.palette.secondary.main,
  0: theme.palette.text.main,
});

export const PROGRESS_BAR_BG_COLOR = (theme) => ({
  100: theme.palette.secondary.light,
  0: 'transparent',
});

export const ERP_ROLES_TO_LMS_ROLES = {
  Student: ['Client'],
  Mentor: ['Mentor'],
  Trainer: ['Trainer'],
  Admin: ['Superadmin', 'Operations manager', 'Coordinator', 'Sales manager', 'Head of Sales', 'Administrator'],
};

export const PERMISSIONS = {
  dashboard: ['Student'],
  programs: ['Student', 'Admin'],
  groups: ['Trainer', 'Mentor'],
  articles: ['Admin', 'Trainer', 'Mentor'],
  grades: ['Student'],
  gradeBook: ['Admin', 'Trainer'],
  submissions: ['Mentor'],
  calendar: ['Student', 'Admin', 'Trainer'], // TODO: temporary hidden for mentor. Need to create endpoind if we need to show it to mentor.
  chat: ['Student', 'Admin', 'Trainer', 'Mentor'],
  settings: ['Student', 'Admin', 'Trainer', 'Mentor'],
};

export const DESKTOP_VIEW_HEIGHT = 'calc(100dvh - 16px)'; // 16px bottom indent
export const MOBILE_HEIGHT = 'calc(100dvh - 104px)'; // 104px top bar height
export const TABLET_HEIGHT = 'calc(100dvh - 88px)'; // 88px top bar height

export const MOBILE_VIEW = (theme) => theme.breakpoints.down('sm');
export const TABLET_VIEW = (theme) => theme.breakpoints.between('sm', 'lg');
export const DESKTOP_VIEW = (theme) => theme.breakpoints.up('lg');

export const STATUSES_COLOR = {
  CANCELLED: 'error',
  ACTIVE: 'info.main',
  PAID: 'secondary.main',
};
