import { memo, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, FormControl, FormControlLabel, Menu, Radio, RadioGroup } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import store from 'store';
import { userActions, userSelectors } from 'store/ducks/user';
import { useSelector } from 'react-redux';
import { transformErpRoleToLmsRole } from 'utils/helpers';
import { ERP_ROLES_TO_LMS_ROLES } from 'utils/constants/common';



const RoleSelector = ({ user, color = 'text.light', hideCurrentRole }) => {
  const currentRole = useSelector(userSelectors.getCurrentRole());
  const navigate = useNavigate();

  const userLmsRoles = [
    ...new Set((user?.roles || []).map((role) => transformErpRoleToLmsRole(ERP_ROLES_TO_LMS_ROLES, role.label))),
  ];

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClickOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (event) => {
    navigate('/');
    store.dispatch(userActions.setCurrentRole(event.target.value));
    handleClose();
  };

  useEffect(() => {
    localStorage.setItem('role', JSON.stringify(currentRole));
  }, [currentRole]);
  return (
    <>
      <Button
        variant="text"
        id="role-select"
        aria-controls={open ? 'role-select-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClickOpen}
        sx={{ minWidth: 0, minHeight: 0, p: 0, color: color, fontSize: 12 }}
      >
        {!hideCurrentRole && currentRole}
        {open ? (
          <KeyboardArrowUpIcon sx={{ marginLeft: 0.5, width: 20, height: 20 }} />
        ) : (
          <KeyboardArrowDownIcon sx={{ marginLeft: 0.5, width: 20, height: 20 }} />
        )}
      </Button>
      <Menu
        id="role-select-menu"
        anchorEl={anchorEl}
        sx={{ px: 3 }}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'role-select',
          role: 'listbox',
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '&::before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              left: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
      >
        <FormControl sx={{ px: 2 }}>
          {userLmsRoles?.map((role) => {
            return (
              <RadioGroup
                key={role}
                name="controlled-radio-buttons-group"
                value={currentRole}
                onChange={(e) => handleChange(e)}
              >
                <FormControlLabel
                  value={role}
                  control={<Radio size="small" />}
                  label={role}
                  sx={{
                    color: 'text.dark',
                    '&.MuiFormControlLabel-root .MuiFormControlLabel-label': { fontSize: 14 },
                  }}
                />
              </RadioGroup>
            );
          })}
        </FormControl>
      </Menu>
    </>
  );
};

export default memo(RoleSelector);
